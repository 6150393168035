import ChainIndicator from '@/components/common/ChainIndicator'
import { useDarkMode } from '@/hooks/useDarkMode'
import { useTheme } from '@mui/material/styles'
import { type ChainInfo } from '@safe-global/safe-gateway-typescript-sdk'
import Link from 'next/link'
import type { SelectChangeEvent } from '@mui/material'
import { ListSubheader, MenuItem, Select, Skeleton } from '@mui/material'
import partition from 'lodash/partition'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useChains from '@/hooks/useChains'
import { useRouter } from 'next/router'
import css from './styles.module.css'
import { useChainId } from '@/hooks/useChainId'
import { type ReactElement, useCallback, useMemo } from 'react'
import { AppRoutes } from '@/config/routes'
import { OVERVIEW_EVENTS, trackEvent } from '@/services/analytics'
import useWallet from '@/hooks/wallets/useWallet'
import type { ChainLink } from '@/components/common/NetworkSelector/chains'
import { EXTERNAL_PRODNETS, EXTERNAL_TESTNETS } from '@/components/common/NetworkSelector/chains'
import LinkIcon from '@/public/images/networks/link.svg'

const NetworkSelector = (props: { onChainSelect?: () => void }): ReactElement => {
  const isDarkMode = useDarkMode()
  const theme = useTheme()
  const { configs } = useChains()
  const chainId = useChainId()
  const router = useRouter()
  const isWalletConnected = !!useWallet()

  const [testNets, prodNets] = useMemo(() => partition(configs, (config) => config.isTestnet), [configs])

  const getNetworkLink = useCallback(
    (shortName: string) => {
      const shouldKeepPath = !router.query.safe

      const route = {
        pathname: shouldKeepPath
          ? router.pathname
          : isWalletConnected
          ? AppRoutes.welcome.accounts
          : AppRoutes.welcome.index,
        query: {
          chain: shortName,
        } as {
          chain: string
          safeViewRedirectURL?: string
        },
      }

      if (router.query?.safeViewRedirectURL) {
        route.query.safeViewRedirectURL = router.query?.safeViewRedirectURL.toString()
      }

      return route
    },
    [router, isWalletConnected],
  )

  const onChange = (event: SelectChangeEvent) => {
    event.preventDefault() // Prevent the link click

    const newChainId = event.target.value
    const shortName = configs.find((item) => item.chainId === newChainId)?.shortName

    if (shortName) {
      trackEvent({ ...OVERVIEW_EVENTS.SWITCH_NETWORK, label: newChainId })
      router.push(getNetworkLink(shortName))
    }
  }

  const renderMenuItem = useCallback(
    (value: string, chain: ChainInfo, isTestnet?: boolean) => {
      return (
        <MenuItem key={value} value={value} className={css.menuItem}>
          <Link href={getNetworkLink(chain.shortName)} onClick={props.onChainSelect} className={css.item}>
            <ChainIndicator chainId={chain.chainId} inline responsive showTestnetIndicator={isTestnet} />
          </Link>
        </MenuItem>
      )
    },
    [getNetworkLink, props.onChainSelect],
  )

  const renderExternalMenuItem = useCallback((value: string, chain: ChainLink, isTestnet?: boolean) => {
    return (
      <MenuItem key={value} value={value} className={css.menuItem}>
        <Link href={chain.link} className={css.item} rel="noopener noreferrer" target="_blank">
          <div className={css.imgContainer}>
            <img
              src={(chain.img as string) ?? undefined}
              alt={`${chain.name} Logo`}
              width={24}
              height={24}
              loading="lazy"
            />
            {isTestnet && <span className={css.testnetIndicator} />}
          </div>
          <span className={css.chainName}>{chain.name}</span>
          <LinkIcon />
        </Link>
      </MenuItem>
    )
  }, [])

  return configs.length ? (
    <Select
      value={chainId}
      onChange={onChange}
      size="small"
      className={css.select}
      variant="standard"
      IconComponent={ExpandMoreIcon}
      MenuProps={{
        transitionDuration: 0,
        sx: {
          '& .MuiPaper-root': {
            overflow: 'auto',
          },
          ...(isDarkMode
            ? {
                '& .Mui-selected, & .Mui-selected:hover': {
                  backgroundColor: `${theme.palette.secondary.background} !important`,
                },
              }
            : {}),
        },
      }}
      sx={{
        '& .MuiSelect-select': {
          py: 0,
        },
      }}
    >
      {prodNets.map((chain) => renderMenuItem(chain.chainId, chain))}
      {EXTERNAL_PRODNETS.map((chain) => renderExternalMenuItem(chain.name + 'mainnet', chain))}
      <ListSubheader className={css.listSubHeader}>Testnets</ListSubheader>

      {testNets.map((chain) => renderMenuItem(chain.chainId, chain, true))}
      {EXTERNAL_TESTNETS.map((chain) => renderExternalMenuItem(chain.name + 'testnet', chain, true))}
    </Select>
  ) : (
    <Skeleton width={94} height={31} sx={{ mx: 2 }} />
  )
}

export default NetworkSelector
